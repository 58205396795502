
export default {
  name: 'IndexPage',
  auth: false,
  async asyncData({ params, $axios }) {
    const policy = await $axios.$get(`/policy`)
    const title = policy?.data?.meta_title?.value;
    const description = policy?.data?.meta_description?.value;
    const meta_keywords = policy?.data?.meta_keywords?.value;
    return {title , description , meta_keywords}
  },
  head()
  {
    return {
      title : this.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.meta_keywords
        },
      ],
    };
  },
  data()
  {
    return {
      parentsData : [],
      child_specialist : null,
      markets : [],
      ads : [],
      specialist_id : null,
      market_name : null,
      specialists : [],
      isLoading: true,
      latest_coupons : [],
    };
  },
  async mounted() {
    let vueThis = this
    await this.getSpecialists()
    await this.getMarkets()
    await this.getAds()
    await this.getLatestCopouns()
    particlesJS('particles-js',

      {
        "particles": {
          "number": {
            "value": 40,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 30,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 80,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0,
            "width": 2
          },
          "move": {
            "enable": true,
            "speed": 1,
            "direction": "top",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 200
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true,
        "config_demo": {
          "hide_card": false,
          "background_color": "#b61924",
          "background_image": "",
          "background_position": "50% 50%",
          "background_repeat": "no-repeat",
          "background_size": "cover"
        }
      }

    );


  var allStores = $(".owl-carousel.all-stores");
  if (allStores.length > 0) {
      allStores.owlCarousel({
          rtl: true,
          items: 8,
          slideBy:'page',
          lazyLoad: true,
          rewind:true,
          autoplay:true,
          autoplayTimeout:5000,
          autoplayHoverPause:true,
          smartSpeed:50,
          pagination: false,
          loop: false,
          dots: false,
          margin: 15,
          nav: true,
          stopOnHover: true,
          navText: ["<i class='fa fa-chevron-right' title='السابق'></i>", "<i class='fa fa-chevron-left' title='التالى'></i>"],
          responsive: {
              0: {
                  items: 2
              },
              350: {
                  items: 2
              },
              479: {
                  items: 3
              },
              768: {
                  items: 4
              },
              992: {
                  items: 6
              },
              1199: {
                  items: 8
              }
          }
      });
  }

  var allStores = $(".owl-carousel.banners");
  if (allStores.length > 0) {
      allStores.owlCarousel({
          rtl: true,
          items: 1,
          lazyLoad: true,
          pagination: false,
          loop: false,
          dots: false,
          rewind:true,
          autoplay:true,
          autoplayTimeout:7000,
          autoplayHoverPause:true,
          nav: true,
          stopOnHover: true,
        navText: ["<i class='fa fa-chevron-right' title='السابق'></i>", "<i class='fa fa-chevron-left' title='التالى'></i>"],
      });
  }

    $('select').select2();

    $(document.body).on("change",".special_id",function(){
      vueThis.specialist_id = null
      vueThis.child_id = null
      vueThis.selectSub(this.value)
    });
    $(document.body).on("change",".child_id",function(){
      vueThis.selectChild(this.value)
    });

  },
  methods : {
    async getMarkets()
    {
      await this.$axios.$get("/marketers" , {
        params : {
          status : "active"
        }
      }).then(({data})=>{
        this.markets = data;
      })
    },
    async getAds()
    {
      await this.$axios.$get("/ads").then(({data})=>{
        this.ads = data;
      })
    },
    async getLatestCopouns()
    {
      await this.$axios.$get("/latest-coupons" , {
        params : {
          // status : "active"
        }
      }).then(({data})=>{
        this.latest_coupons = data;
      })
    },
    async getSpecialists(e) {
      this.specialist_id = "all"
      await this.$axios.$get("/specialists" , {
        params : {
          status : "active"
        }
      }).then(({data})=>{
        console.log("data" , data)
        this.specialists = data;
      })

      this.isLoading = false

    },
    async search(e) {
      this.isLoading = true
      let formData = new FormData(e.target)
      let dataForm = JSON.stringify(Object.fromEntries(formData));
      let finalData = JSON.parse(dataForm)
      console.log("data" , finalData.specialist_id)
      await this.$router.push({
        path : "stores",
        query : {
          specialist_id: finalData.specialist_id ,
          market_name: finalData.market_name ,
          child_specialist: finalData.child_specialist ,
        }
      })
      this.isLoading = false
    },async increase_visits(id) {
      await this.$axios.$post("/increase-visits-ads/" + id , {
        type : "website"
      }).then(({data})=>{
        console.log("data" , data)
      })
    },
    selectSub(selected_id)
    {
      this.parentsData = []

      if (selected_id != "all")
      {
        this.specialist_id = selected_id;
        console.log("selected" , selected_id)
        this.specialists.map((val , index)=>{
          if (selected_id == val?.id)
          {

            this.parentsData = val?.childs
            $("#disabled_select").val(1).prop('selected',true);

            // console.log("dddddddd" , val?.childs)
          }
        })

        $('select').select2();
      }else{
        this.parentsData = []
      }




      // console.log("dddddddd" , this.parentsData)
    },
    selectChild(selected_id)
    {
      this.child_specialist = selected_id;
    },
  }
}
